import React,{useState} from "react"
import {Container,Row,Col,Navbar,Nav, Button} from 'react-bootstrap';
import { Link } from "gatsby"
import FsLightbox from 'fslightbox-react';
import VideoImg6 from "../../assests/images/video6.jpg";
import VideoImg7 from "../../assests/images/video7.jpg";

import {youtube_parser} from "../../components/utils/common"
// import ModalVideo from 'react-modal-video'
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';

import VideoModule from "./video-module"

function SampleVideos(props){
    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });

    const [open_modal, setOpenModal] = useState(false);
    const [video_id, setVideoId] = useState('');

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }

    const openModel = (video_url) => {
      var youtube_id = youtube_parser(video_url);
      setVideoId(youtube_id);
      setTimeout(function(){
        setOpenModal(true);
      },500)
      
    }


    var ExampleVideos = [];

    if(props.sample_videos){
      

      props.sample_videos.Videos_Block.map((videos, index) => {
        var video_img = videos.Embed_Video_URL ? 'https://i3.ytimg.com/vi/'+youtube_parser(videos.Embed_Video_URL)+'/maxresdefault.jpg' : '';
        if(videos.Embed_Video_URL){
          ExampleVideos.push({'src_url': videos.Image[0] ? strapi_url+''+videos.Image[0].url : video_img, 'title': videos.Title, 'video_url': videos.Embed_Video_URL, 'type': 'video'})
        }
        
      })
    }
    

    var lightImages2 = ExampleVideos && ExampleVideos.map(img => img.type === 'image' ? img.src_url : img.video_url);

    //console.log('lightboxController', lightboxController);
    //console.log('lightImages2', lightImages2);
    
    return (
    <>
        <div className="section-wrapper property-sec-wrap property-sec-block2">
          <Container>
            <div className="example-wrapper">
                <h2>{props && props.sample_videos && props.sample_videos.Example_Videos_Heading}</h2>

                {/* <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={lightImages2}
                    slide={lightboxController.slide}
                /> */}

                

                <div className="customer-gallery">

                  {
                    ExampleVideos.length > 0 &&  ExampleVideos.map((item, index) => {
                      return(
                        <div className="customer-block">
                          <div className="customer-card">
                              <div className="video-wrap rectangle_video slider_vdeo">
                                {
                                  item.src_url ? (
                                    // <figure>
                                    //   <img src={item.src_url} alt="video-img" />
                                    // </figure>
                                    <VideoModule video_url={item.video_url} playing={false} loop={true} width={'100%'} height={'100%'} /> 
                                  ) : (
                                    <span className="coming_soon">Video Coming Soon</span>
                                  )
                                }

                                {/* {
                                  item.video_url && (
                                    <a href="javascript:;">
                                      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0)">
                                      <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                      </g>
                                      <defs>
                                      <clipPath>
                                      <rect width="22" height="25.2703" fill="white"/>
                                      </clipPath>
                                      </defs>
                                      </svg>
                                    </a>
                                  )
                                } */}
                              </div>

                              <div className="customer-name">
                                <h3>{item.title}</h3>
                              </div>

                          </div>

                      </div>
                      )
                    })
                  }

                  

            </div>
            {/* customer-gallery */}

            {/* <ModalVideo channel="youtube" autoplay mute={1} modestbranding={null} isOpen={open_modal} videoId={video_id} onClose={() => setOpenModal(false)} /> */}

            </div>
            {/* property-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}
    </>
    )
}

export default SampleVideos