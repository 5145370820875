import React, {useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"
import {Container,Navbar,Nav, Row} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import ExampleVideos from  "../../components/modules/example-videos"; 
import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import Title_Block from  "../modules/title_block"; 

import VideoModule from "./video-module"

import TimeTrackerComp from "./time_tracker";
import ScrollAnimation from 'react-animate-on-scroll';

const PresentationPage = (props) => {

    const [ playVideo, setPlayVideo ] = useState(false);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('introducing-property-launch');

  //   const data = useStaticQuery(graphql`
  //   query PropertyLaunchQuery {      
  //       glstrapi {
  //           articles(where: {URL: "introducing-property-launch"}) {
  //             Title
  //             URL
  //             Meta_Title
  //             Meta_Description
  //             Content
  //             Image {
  //               url
  //               alternativeText
  //             }
  //             Modules {
  //               ... on GLSTRAPI_ComponentModulesIntroContent {
  //                 id
  //                 CTA_1_Label
  //                 CTA_1_URL
  //                 CTA_2_Label
  //                 CTA_2_URL
  //                 Content
  //                 Title
  //                 Upload_Image {
  //                   url
  //                   alternativeText
  //                 }
  //                 Upload_Video {
  //                   url
  //                 }
  //                 Embed_Video_URL
  //               }
  //               ... on GLSTRAPI_ComponentModulesImpactOfPropertyLaunch {
  //                 id
  //                 Content
  //                 Embed_Video_Link
  //                 Heading
  //                 Image {
  //                   alternativeText
  //                   url
  //                 }
  //               }
  //               ... on GLSTRAPI_ComponentModulesExampleVideos {
  //                 id
  //                 Example_Videos_Heading
  //                 Videos_Block {
  //                   Embed_Video_URL
  //                   Title
  //                   id
  //                   Upload_Image {
  //                     url
  //                     alternativeText
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //       }
  //   }
  // `)

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("article_data", article_data);
    

    return <>
     <TimeTrackerComp TabName={'Launch'} />

     <div className="section-wrapper">
          <Container>
            <div className="">

                <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={true}  />

                <div className="employee-wrapper">
                    {/* <div className="employee-content">
                        {article_data && HTMLReactParser(article_data.Modules[0].Content)}
                    </div> */}
                    {/* employment-content */}

                    <div className="property-launch-video">

                        <div className="video-wrap rectangle_video">   
                        
                        
                        {
                            article_data && article_data.Modules && <VideoModule video_url={article_data.Modules.length > 0 && article_data.Modules[0].Upload_Video ? strapi_url+''+article_data.Modules[0].Upload_Video.url : article_data.Modules[0].Embed_Video_URL} width="100%" height="100%" playing={false} preview_url={article_data.Modules[0].Upload_Image && strapi_url+''+article_data.Modules[0].Upload_Image.url} />
                        }
                        
                        
                        {/* {
                            playVideo ? (
                                <VideoModule video_url={article_data && article_data.Modules[0].Upload_Video ? strapi_url+''+article_data.Modules[0].Upload_Video.url : article_data.Modules[0].Embed_Video_URL} />
                            ) : (
                                <>
                                    <figure>
                                        { article_data && article_data.Modules[0].Upload_Image && <img src={strapi_url+''+article_data.Modules[0].Upload_Image.url} alt="video-img" /> }
                                    </figure>
                                    <a href="javascript:;" onClick={() => setPlayVideo(true) }>
                                        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                        <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                        </g>
                                        <defs>
                                        <clipPath>
                                        <rect width="22" height="25.2703" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </a>
                                </>
                            )
                        }                             */}
                        </div>
                    </div>

                
              {/* video-wrap */}
            </div>
            {/* employee-wrapper */}
            </div>
            {/* property-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}

        {/* <div className="section-wrapper property-sec-wrap">
          <Container>
            <div className="property-wrapper">
                <h2>{article_data && article_data.Modules[1] && article_data.Modules[1].Why_Use_Property_Launch_Heading}</h2>
                <div className="property-wrap">
                    {
                        article_data && article_data.Modules && article_data.Modules[1].Stats_Block.map((stats, index) => {
                            return(
                                <div class="property-card">
                                    <div class="property-block">
                                        <strong>{stats.Title}</strong>
                                        <p>{stats.Content}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

          </Container>
      </div> */}
      {/* section-wrapper */}

      <div className="section-wrapper property-sec-wrap property-sec-block mt-5">
          <Container>
            <div className="property-wrapper">
                <h2>{article_data && article_data.Modules[1].Heading}</h2>
                <div className="property-sec">
                    <div className="right-aside">
                      <div className="graph-wrap">
                          {/* <h3>Property Launch vs Standard Property Listing</h3> */}
                          <ScrollAnimation animateIn='fadeIn' delay={250} animateOnce={true}>
                          <figure>
                            { article_data && article_data.Modules && <img src={strapi_url+''+article_data.Modules[1].Image.url} alt="" /> }
                          </figure>
                          </ScrollAnimation>
                          {/* <div className="video-wrap"> 
                            {
                                article_data && article_data.Modules && <VideoModule video_url={article_data.Modules.length > 0 && article_data.Modules[1].Embed_Video_Link} width="100%" height={"300px"} playing={false} preview_url={article_data.Modules[1].Image && strapi_url+''+article_data.Modules[1].Image.url} />
                            }
                            {'   '}                            
                        </div> */}
                      </div>
                      {/* graph-wrap */}
                   </div>
                   {/* right-aside */}

                   <div className="left-aside">
                        {article_data && article_data.Modules[1].Content && HTMLReactParser(article_data.Modules[1].Content)}
                   </div>
                   {/* left-aside */}
                   
                </div>
                {/* property-wrap */}
            </div>
            {/* property-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}
  
      <ExampleVideos sample_videos={article_data && article_data.Modules[2]} />

    </>

}

export default PresentationPage