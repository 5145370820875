import { useState, useEffect } from 'react';
import {isBrowser} from "../../services/auth"

export const numberWithCommas = ( x ) => {
    if (x === 0) {
      return '';
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const uniqueID = () => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    for (var i = 0; i < 36; i++){
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }      
    return text; 
}

export  const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";
export const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberOnly = (value) => {
  if(value){
    if(!/^[0-9]+$/.test(value)){
      //alert("Please only enter numeric characters.")
      return "Please enter  only numeric characters.";
    }
  }  
}

export const decimalNumberOnly = (value) => {
  if(value){
    if(!/^[0-9 .]+$/.test(value)){
      //alert("Please only enter numeric characters.")
      return true;
    } else{
      return false;
    }
  }  
}

export const mobileNumberOnly = (value) => {
  if(value){
    if(!/^[0-9 +]+$/.test(value)){
      //alert("Please only enter numeric characters.")
      return true;
    } else{
      return false;
    }
  }  
}

export const youtube_parser =(url) => {
  if(url){
    var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    var match = url.match(regExp);
    return (match && match[1].length==11)? match[1] : false;
  }
  
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = isBrowser() && window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}