import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import SEO from "../../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import VideoImg1 from "../../assests/images/pro_launch.jpg"; 
import VideoImg6 from "../../assests/images/video6.jpg";
import VideoImg7 from "../../assests/images/video7.jpg";
import GraphImg from "../../assests/images/graph.jpg";

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import ExampleVideos from  "../../components/modules/example-videos"; 
import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import VideoModule from "../../components/modules/video-module"

import PropertyLaunch from "../../components/modules/property_launch"

const PresentationPage = () => {

    const [ playVideo, setPlayVideo ] = useState(false);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('introducing-property-launch');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
    <div className="pre-wrapper">

        <Header />

        <PropertyLaunch presentation_type="preval" />
      
        <NavLink tab_name="Marketing" prevLink={config.preval_people}  nextLink={config.preval_valuation_day} />

    </div>
    </>
}

export default PresentationPage